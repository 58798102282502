.btnContainer {
    display: flex;
    flex-direction: row-reverse;
    filter: drop-shadow(0px 5px 10px rgba(0, 00, 0, 0.3));
  }
  
  .chatButtonClosed {
    align-items: center;
    background: var(--bs-white);
    border: none;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
    color: var(--bs-brand-cinder);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    float: right;
    height: 60px;
    justify-content: center;
    outline: none;
    transition: background 0.5s ease;
  }
  
  .chatButtonClosed span {
    padding: 0;
    margin: 0 20px 0 10px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    font-family: Roboto;
    color: #464c51;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    width: 80px;
  }
  
  .chatButtonClosed img {
    width: 40px;
    margin-left: 10px;
  }
  
  .chatButtonOpened {
    color: #ffffff;
    background: #ffb81c !important;
    height: 60px;
    border: none;
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    outline: none;
    cursor: pointer;
    transition: background 0.5s ease;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 20px),
      calc(100% - 20px) 100%,
      0 100%
    );
  }
  
  .chatButtonOpened span {
    padding: 0;
    margin: 0 20px 0 10px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    font-family: Roboto;
    color: #464c51;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    width: 80px;
  }
  
  .chatButtonOpened img {
    width: 40px;
    margin-left: 10px;
  }
  
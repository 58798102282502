.chatHistory {
  border-bottom: 1px solid #ffb81c;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  margin-bottom: 10px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.messageContainer {
  display: flex;
  flex-direction: column;
}

.chatMessage {
  margin: 5px 10px 5px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 80%;
}

.chatMessage p {
  margin: 0;
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
}

.chatMessage.user {
  align-self: flex-end;
  background-color: #eee;
  color: black;
}

.chatMessage.bot {
  align-self: flex-start;
  background-color: #ffb81c;
  color: black;
  display: flex;
  flex-direction: column;
}

.chatMessage.bot:nth-child(1) {
  align-self: flex-start;
  background: none !important;
  max-width: 100% !important;
  color: white;
  width: 100%;
  text-align: center;
}

.chatHistory::-webkit-scrollbar-track {
  background-color: black;
}

.chatHistory::-webkit-scrollbar {
  width: 10px;
  background-color: black;
}

.chatHistory::-webkit-scrollbar-thumb {
  background-color: #ffb81c;
}

.BoxresponseButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}
nav {
  justify-content: center;
  display: flex;
}

nav ul {
  padding-left: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between !important;
  flex-direction: row !important;
  display: flex;
}

nav li {
  list-style: none;
}

nav a {
  font-family: Roboto;
  color: #464c51;
  line-height: 24px;
  margin: 0 24px;
  padding: 24px 0 20px;
  background-color: unset;
  border-bottom: 4px solid #0000;
  font-size: 16px;
  text-decoration: none;
  font-weight: 500;
  height: auto !important;
  display: inline-block !important;
}

nav a:hover {
  border-bottom: 4px solid #ffb81c;
}

.contacts {
  display: flex !important;
}

a.button {
  color: #fff;
  background-color: #151a20;
  border: 0;
  padding: 12px 32px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  letter-spacing: 0.4px;
  display: inline-flex;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.button:hover {
  background-color: #ffb81c;
  color: #000;
}

.navRight {
  justify-content: flex-end !important;
  display: inline-flex !important;
}

footer {
  display: flex;
  flex-direction: column;
  min-height: 20vh;
  background-color: rgba(17, 21, 27, 1) !important;
  padding-top: 40px;
  padding-bottom: 10px;
}

.footer {
  max-width: 1160px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
}

.copyright {
  display: flex;
  flex-direction: column;
}

.copyright span {
  margin: 20px 0;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.7;
  color: rgba(var(--bs-brand-white-rgb), 1) !important;
}

.copyright p {
  margin-top: 40px;
  font-size: 12px;
  --bs-text-opacity: 1;
  color: rgba(
    var(--bs-akkodis-smokey-grey-rgb),
    var(--bs-text-opacity)
  ) !important;
  font-family: Roboto;
  font-weight: 400;
}

.socialMedias {
  display: flex;
  flex-direction: column;
}

ul.listMedia {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  list-style: none;
}

ul.listMedia li {
  list-style: none;
  display: flex;
}

ul.listMedia a {
  font-size: 24px;
  margin-left: 13px;
  color: var(--bs-brand-silver-sand-grey);
  text-decoration: none;
}

ul.listMedia a:hover {
  color: var(--bs-brand-white);
}

@font-face {
  font-family: adeccoicons;
  src: local('adeccoicons'), url(../../assets/fonts/adeccoicons.ttf) format("truetype");
  font-style: normal;
  font-display: swap;
}


.iconFacebook:after {
  font-family: adeccoicons;
  content: '\E802';
}

.iconTwitter:after {
  font-family: adeccoicons;
  content: '\E800';
}

.iconLinkedin:after {
  font-family: adeccoicons;
  content: '\E801';
}

.iconInstagram:after {
  font-family: adeccoicons;
  content: "\E80B";
}

.widget {
  bottom: 20px;
  position: fixed;
  right: 20px;
  z-index: 999999999;
}

.vAssistant {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #ffb81c;
  bottom: 80px;
  display: flex;
  filter: drop-shadow(0px 5px 10px rgba(0, 00, 0, 0.8));
  flex-direction: column;
  height: 70vh;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px;
  position: fixed;
  right: 20px;
  width: 90vw;
  max-width: 500px;
  z-index: 999999999;
}

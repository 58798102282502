main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 72px;
}

h1 {
  font-family: TiemposRegular;
  font-size: 76px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 16px;
  color: #fff;
}

h1 span {
  color: #ffb81c;
}

section.headerSection h1 {
  cursor: default;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 350px;
  align-items: center;
  font-size: 7vh;
}

/* SectionHeader */

.headerSection {
  display: flex;
  position: relative;
  min-height: 100vh;
}

.headerSection .backgr {
  z-index: -1;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.backgrGrad {
  background: linear-gradient(64.27deg, #0ff0 59.67%, #00ffffe6),
    linear-gradient(84.05deg, #00111a, #00111a66 50.92%, #00111a1a),
    linear-gradient(87.02deg, #ffb81cb3 2.48%, #ffb81cb3 61.91%, #ffb81c00);
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
}

.backgr img {
  height: auto;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.backgr video {
  height: auto;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.headerSection .text {
  // padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  align-items: center;
  position: relative;
  width: 1160px;
  margin-right: auto;
  margin-left: auto;
}

/* Section contentSection */

.contentSection {
  position: relative;
  display: flex;
  padding-top: 50px;
}

.backgrDark {
  z-index: -1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  background: rgba(0, 31, 51, 1);
  border-top: 30px solid rgba(0, 17, 26, 1);
}

.white {
  flex-direction: row !important;
  display: inline-flex;
  padding: 50px 0 0 0 !important;
  color: white;
  flex-wrap: wrap;
}

.white h2 {
  color: white;
  margin-top: 0;
}

.white h3 {
  font-family: Roboto;
  font-size: 44px;
  font-weight: 600;
  color: rgba(var(--bs-brand-white-rgb), var(--bs-text-opacity)) !important;
  line-height: 1.09;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
  margin-top: 0;
}
.columnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 580px;
}

// .columnContainer div {
//   max-width: 360px;
// }

.listStyleWhTxt > li {
  color: white;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
}

.listStyleWhTxt > li::marker {
  color: #ffb81c;
}

.contentSection .text {
  width: 90vw;
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 50px;
}

.listCloud {
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  max-width: 550px;
  padding-left: 0;
  list-style: none;
  margin-top: 0;
  margin-bottom: 1rem;
}

.listCloud > li {
  border-top: 2px solid var(--bs-brand-secondary);
  width: 100%;
  max-width: 250px;
  padding: 24px 0;
  list-style: none;
}

.listCloud > li > p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.15px;
}

.backgrTriangle img {
  position: absolute;
  max-width: 50%;
  max-height: 85%;
  right: 0px;
  bottom: -1px;
}

h2 {
  font-family: TiemposRegular, sans-serif !important;
  font-size: 2.75rem;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 16px;
  color: #000;
}

.gallery {
  display: flex;
}

.gallery img {
  cursor: pointer;
  width: 100%;
  max-width: 500px;
}

.twoColumnsContainer {
  font-family: Roboto;
  font-weight: 400;
  font-size: 28px;
  line-height: 121%;
  letter-spacing: 0.15px;
  column-gap: 32px;
  min-height: 288px;
  padding-top: 32px;
  flex-direction: row !important;
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap;
}

.boxContainer {
  filter: drop-shadow(0 55px 33px rgba(0, 0, 0, 0.01))
    drop-shadow(0 24px 24px rgba(0, 0, 0, 0.02))
    drop-shadow(0 6px 13px rgba(0, 0, 0, 0.02))
    drop-shadow(0 0 0 rgba(0, 0, 0, 0.02));
  height: auto !important;
  width: 100% !important;
  display: flex !important;
}

.boxContainer:hover {
  filter: drop-shadow(0 58px 23px rgba(0, 0, 0, 0.01))
    drop-shadow(0 32px 19px rgba(0, 0, 0, 0.03))
    drop-shadow(0 14px 14px rgba(0, 0, 0, 0.05))
    drop-shadow(0 4px 8px rgba(0, 0, 0, 0.06))
    drop-shadow(0 0 0 rgba(0, 0, 0, 0.06));
}

.boxBlock {
  padding: 20px;
  background: var(--bs-brand-white);
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--clip-corner-lateral)),
    calc(100% - var(--clip-corner-lateral)) 100%,
    0 100%
  );
  display: flex;
  flex-direction: column;
  width: 100%;
}

.boxTitle {
  color: var(--bs-brand-cinder);
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.boxTitle > img,
.boxTitle > span {
  display: flex;
  margin-right: 30px;
}

.listStyleBlTxt {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
}

.listStyleBlTxt li {
  list-style: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin: 10px 0;
  height: 50px;
}
.listStyleBlTxt span {
  color: black;
  display: flex;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  flex-direction: column;
}

.listStyleBlTxt span p {
  margin: 0;
  display: flex;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--bs-body-color);
}
.listStyleBlTxt img {
  width: 40px;
  height: 40px;
  display: flex;
  margin-right: 15px;
}

.listStyleBlTxt li.indent {
  padding-left: 55px;
}

.columnHalf {
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
}

.columnHalf h3 {
  font-family: TiemposRegular;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.25;
  margin-bottom: 24px;
  margin-top: 0;
}

.columnHalf p {
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
}

.columnHalf img {
  width: 100%;
  max-width: 550px;
  object-fit: cover;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--clip-corner-lateral)),
    calc(100% - var(--clip-corner-lateral)) 100%,
    0 100%
  );
}

.tabContainer {
  list-style: none;
  padding: 0;
  margin-top: 72px;
  margin-bottom: 80px;
  display: flex;
}

.tabButton {
  cursor: pointer;
  padding: 24px;
  margin: 0;
  width: 100%;
  border: 1px solid #ebeeef;
  background: var(--bs-brand-desert-storm);
  padding: 24px;
  flex: 1 1 auto !important;
  text-transform: none;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.tabContainer .active,
.tabButton:hover {
  background: var(--bs-brand-white);
  box-shadow: 0 93px 37px #00000003, 0 52px 31px #00000005,
    0 23px 23px #0000000a, 0 6px 13px #0000000d, 0 0 0 #0000000d;
}

.tabButton > span {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
  margin: 0;
  color: var(--bs-brand-cinder);
  cursor: pointer;
  text-transform: none;
  list-style: none;
}

.tabPages .tabPage {
  display: none;
  animation: fadeEffect 1s;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.columnFlex {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
}

.columnFlex > .boxContainer {
  margin-bottom: 32px;
}

#fullpage {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-color: black;
}

/* YellowBanner Section */

.contacts {
  justify-content: flex-end !important;
  display: flex !important;
}

a.button {
  color: #fff;
  background-color: #151a20;
  border: 0;
  padding: 12px 32px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  letter-spacing: 0.4px;
  display: inline-flex;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

a.button:hover {
  background-color: #ffb81c;
  color: #000;
}

.yellowBanner {
  position: relative;
  display: flex;
}

.bannerBackground {
  z-index: -1;
  overflow: hidden;
  background: linear-gradient(
    258.97deg,
    var(--bs-brand-tertiary) 0,
    var(--bs-brand-secondary) 26.62%
  );
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
}

.bannerContent {
  padding-top: 7.75rem;
  padding-bottom: 7.75rem;
  flex-direction: row;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  justify-content: space-between;
  max-width: 1160px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bannerContent > div {
  flex-direction: column;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 900px;
}

.bannerContent h2 {
  letter-spacing: -1.5px;
  padding: 0 40px;
  margin: 0;
}
.connectButton {
  flex-shrink: 0 !important;
  flex-direction: column !important;
  display: flex !important;
  margin-top: 30px;
  width: 190px !important;
}

.connectButton a {
  background: var(--bs-brand-fade-black) !important;
}

.connectButton a:hover {
  color: var(--bs-white);
  background: var(--bs-akkodis-astronaut-blue) !important;
}

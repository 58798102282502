.chatInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatInput input {
  flex-grow: 1;
  padding: 0 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  line-height: 38px;
  outline: none;
}

.chatInput input:focus {
  border: 1px solid #ffb81c;
}

.chatInput button {
  padding: 5px 20px;
  border: none;
  color: white;
  background: var(--bs-brand-fade-black);
  cursor: pointer;
}

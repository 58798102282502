.headerSection {
  min-height: 650px;
  display: flex !important;
  height: 100%;
}

.headerSection .text {
  padding-top: 82px;
  padding-bottom: 0 !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.headerSection .backgr {
  z-index: -1;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.backgr img {
  height: auto;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  object-position: center center;
}

.backgrGrad {
  background: linear-gradient(64.27deg, #0ff0 59.67%, #00ffffe6),
    linear-gradient(84.05deg, #00111a, #00111a66 50.92%, #00111a1a),
    linear-gradient(87.02deg, #ffb81cb3 2.48%, #ffb81cb3 61.91%, #ffb81c00);
  mix-blend-mode: multiply;
  position: absolute;
  z-index: 999;
  height: 100%;
  width: 100%;
}

h1 {
  margin: 0;
}

.loginFormContainer {
  width: 90%;
  max-width: 350px;
  filter: drop-shadow(0 58px 23px rgba(0, 0, 0, 0.01))
    drop-shadow(0 32px 19px rgba(0, 0, 0, 0.03))
    drop-shadow(0 14px 14px rgba(0, 0, 0, 0.05))
    drop-shadow(0 4px 8px rgba(0, 0, 0, 0.06))
    drop-shadow(0 0 0 rgba(0, 0, 0, 0.06));
  display: flex;
  min-height: 460px;
}

.loginForm {
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--clip-corner-lateral)),
    calc(100% - var(--clip-corner-lateral)) 100%,
    0 100%
  );
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  padding: 40px;
}

.formHeader {
  font-family: Roboto;
  font-weight: 400;
  font-size: 28px;
  line-height: 121%;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  cursor: default;
  user-select: none;
}

.formHeader > img {
  margin-right: 30px;
}

.formContent {
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
}

.formContent input {
  padding: 0 10px;
  border: 1px solid #ccc;
  margin-right: 10px;
  font-family: Roboto;
  font-weight: 300;
  font-size: 18px;
  line-height: 38px;
  outline: none;
  width: 100%;
}

.formContent input:focus {
  border: 1px solid #ffb81c;
}

.formContent button {
  color: #fff;
  background-color: #151a20;
  border: 0;
  padding: 12px 32px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  letter-spacing: 0.4px;
  display: inline-flex;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formContent button:hover,
.formContent button:focus {
  background-color: #ffb81c;
  color: #000;
}

.pageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  // min-height: 100vh;
  // height: auto;
}
*, ::after, ::before {
  box-sizing: border-box;
}

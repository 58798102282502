header {
  flex-direction: column;
  position: relative;
  display: flex;
}

.brandWhite {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  height: 72px;
  background: #fff;
  border-bottom: 1px solid #ebeeef;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
}

.logo {
  display: flex;
}

.signOutContainer {
  display: flex !important;
  margin-left: 20px;
}

.signOutbtn {
  color: #000;
  background-color: white;
  border: 0;
  padding: 12px 32px;
  font-family: Roboto;
  font-weight: 600;
  font-size: 16px;
  border-radius: 2px;
  letter-spacing: 0.4px;
  display: inline-flex;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.signOutbtn:hover {
  background-color: #ffb81c;
  color: #000;
}

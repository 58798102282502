.responseButtonContainer {
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.5));
    display: flex;
    margin-right: 20px;
    margin-bottom: 10px;
}

.responseButton {
    align-items: center;
    background: rgb(0, 31, 51);
    border: none;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    color: var(--bs-white);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    outline: none;
    padding: 5px 10px 5px 5px;
}

.responseButton:hover {
    color: #ffb81c;
}
.chatBoxHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ffb81c;
  margin-bottom: 5px;
}

.chatBoxHeader p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  color: var(--bs-brand-white);
  margin: 10px 0 10px 20px;
  border-left: 2px solid white;
  padding-left: 20px;
  line-height: 28px;
}

.chatBoxHeader p span {
  color: var(--bs-akkodis-secondary);
}
